import { AmplitudeClient } from "@heyhabito/amplitude"
import { logSubmittedForm } from "@heyhabito/amplitude/src/lib"
import { useSentry } from "@heyhabito/sentry"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  Alert,
  Body,
  breakpoints,
  Card,
  GBPInputQuestion,
  horizontal,
  mixins,
  typographyStyles,
  vertical,
} from "design-kit"
import React from "react"

import { Slider } from "./Slider"
import { APIResponse, HeadingLevel } from "./types"
import { createHeading, formatPence } from "./utils"

interface Props {
  amplitudeClient: AmplitudeClient
  headingLevel: Exclude<HeadingLevel, 6>
  title: string
}

const FormCard = styled(Card)`
  display: flex;
  flex-direction: column;

  :first-of-type {
    margin-top: ${vertical.s};
  }

  :not(:first-of-type) {
    margin-top: ${vertical.xs};
  }

  > :last-child {
    margin-top: ${vertical.s};
  }

  ${breakpoints.desktop`
    flex-direction: row;

    > div:first-of-type {
      box-sizing: border-box;
      padding-right: ${horizontal.xxl};
    }

    > * {
      flex-basis: 50%;

      :last-child {
        margin-top: 0;
      }
    }
  `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${typographyStyles.headlineBold}
  }
`

const InputsContainer = styled.div`
  > div {
    margin-top: ${vertical.s};
  }
`

const requestErrorMessage = (
  <Alert kind="destructive" title="Something went wrong">
    Please try different values.
  </Alert>
)

const MAX_DEPOSIT = 1500000
const MAX_INTEREST_RATE = 10.0
const MAX_PROPERTY_VALUE = 1500000
const MAX_RENTAL_INCOME = 5000

const GENERIC_ERROR_MESSAGE =
  "Something went wrong. Please try different values."

interface APIState {
  debounceHandler: NodeJS.Timeout | null
  fieldError: {
    deposit?: string
    interestRate?: string
    form?: string
    propertyValue?: string
    rentalIncome?: string
  } | null
  hasRequestFailed: boolean
  response: APIResponse | null
}

export const BTLCalculator: React.FunctionComponent<Props> = ({
  amplitudeClient,
  headingLevel,
  title,
}) => {
  const [propertyValue, setPropertyValue] = React.useState("300000")
  const [rentalIncome, setRentalIncome] = React.useState("1500")
  const [deposit, setDeposit] = React.useState("100000")
  const [interestRate, setInterestRate] = React.useState("2.5")

  const [apiState, updateAPIState] = React.useReducer<
    React.Reducer<APIState, Partial<APIState>>
  >(
    (prevState, updates) => ({
      ...prevState,
      ...updates,
    }),
    {
      debounceHandler: null,
      fieldError: null,
      hasRequestFailed: false,
      response: null,
    }
  )

  const { logToSentryDetailed } = useSentry()

  const formTitle = createHeading(headingLevel, title)

  const subHeadingLevel = (headingLevel + 1) as HeadingLevel

  const borrowTitle = createHeading(
    subHeadingLevel,
    "See what you could borrow"
  )

  const monthlyMortgageTitle = createHeading(
    subHeadingLevel,
    "See your monthly mortgage cost"
  )

  const validateAndSubmitData = (): void => {
    if (apiState.debounceHandler) {
      clearTimeout(apiState.debounceHandler)
    }

    const parsedDeposit = parseInt(deposit)
    const parsedInterestRate = parseFloat(interestRate)
    const parsedPropertyValue = parseInt(propertyValue)
    const parsedRentalIncome = parseInt(rentalIncome)

    const isPropertyValueValid =
      !Number.isNaN(parsedPropertyValue) && parsedPropertyValue > 0

    const isDepositValid =
      !Number.isNaN(parsedDeposit) &&
      parsedDeposit > 0 &&
      !Number.isNaN(parsedPropertyValue) &&
      parsedDeposit >= 0.2 * parsedPropertyValue

    const isInterestRateValid = !Number.isNaN(parsedInterestRate)

    const isRentalIncomeValid =
      !Number.isNaN(parsedRentalIncome) && parsedRentalIncome > 0

    if (
      !isPropertyValueValid ||
      !isDepositValid ||
      !isInterestRateValid ||
      !isRentalIncomeValid
    ) {
      updateAPIState({
        fieldError: {
          ...(!isDepositValid && {
            deposit: Number.isNaN(parsedDeposit)
              ? "The deposit amount is required."
              : parsedDeposit === 0
              ? "Your deposit has to be greater than 0."
              : "Your deposit has to be at least 20% of the property value.",
          }),
          ...(!isInterestRateValid && {
            interestRate: "The interest rate is required.",
          }),
          ...(!isPropertyValueValid && {
            propertyValue:
              parsedPropertyValue === 0
                ? "The property value has to be greater than 0."
                : "The property value is required.",
          }),
          ...(!isRentalIncomeValid && {
            rentalIncome:
              parsedRentalIncome === 0
                ? "The rental income has to be greater than 0."
                : "The rental income is required.",
          }),
        },
      })

      return
    }

    const newDebounceHandler = setTimeout(async () => {
      logSubmittedForm(
        amplitudeClient,
        "btl-calculator",
        {
          deposit: parsedDeposit,
          interestRate: parsedInterestRate,
          propertyValue: parsedPropertyValue,
          rentalIncome: parsedRentalIncome,
        },
        "buy_to_let_mortgage_calculator"
      ).catch(() => {
        // Prevent logs from breaking the application
        return
      })

      const body = JSON.stringify({
        depositV2: (parsedDeposit * 100).toString(),
        interestRateV2: parsedInterestRate,
        mortgageTerm: 25,
        propertyValueV2: (parsedPropertyValue * 100).toString(),
        rentalIncome: (parsedRentalIncome * 100).toString(),
      })

      let response: Response | undefined

      try {
        response = await fetch("/api/calculator/v2/buy-to-let", {
          body,
          headers: {
            Authorization: "Basic SGFiaXRvOiE4T3VWOCRBI05MNA==",
            "Content-Type": "application/json",
          },
          method: "POST",
        })

        if (response.status !== 200) {
          throw new Error(response.status + " response status code")
        }

        const data: APIResponse = await response.json()

        updateAPIState({
          debounceHandler: null,
          fieldError: null,
          hasRequestFailed: false,
          response: data,
        })
      } catch (e) {
        const error = e instanceof Error ? e : new Error(`${e}`)
        updateAPIState({
          debounceHandler: null,
          fieldError: null,
          hasRequestFailed: true,
        })

        if (response && response.status >= 500) {
          logToSentryDetailed({
            description: "Error calling the BTL Calculator API",
            info: {
              body,
              cause: error,
              response: await response.text(),
            },
          })
        }
      }
    }, 500)

    updateAPIState({ debounceHandler: newDebounceHandler })
  }

  React.useEffect(() => {
    validateAndSubmitData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deposit, interestRate, propertyValue, rentalIncome])

  return (
    <form
      css={css`
        > h1,
        > h2,
        > h3,
        > h4,
        > h5 {
          ${typographyStyles.headline3}
        }
      `}
      onSubmit={event => {
        event.preventDefault()

        validateAndSubmitData()
      }}
    >
      {(apiState.hasRequestFailed || apiState.fieldError) && (
        // Centralized error message for assistive technology
        <p css={css(mixins.invisible)} role="alert">
          {apiState.hasRequestFailed
            ? GENERIC_ERROR_MESSAGE
            : apiState.fieldError?.form ||
              apiState.fieldError?.propertyValue ||
              apiState.fieldError?.rentalIncome ||
              apiState.fieldError?.deposit ||
              apiState.fieldError?.interestRate ||
              GENERIC_ERROR_MESSAGE}
        </p>
      )}

      {formTitle}

      <FormCard element="div">
        <InputsContainer>
          {borrowTitle}

          <GBPInputQuestion
            error={apiState.fieldError?.propertyValue}
            onInput={value => {
              const boundedValue = Math.min(
                parseInt(value),
                MAX_PROPERTY_VALUE
              ).toString()

              if (boundedValue !== propertyValue) {
                setPropertyValue(boundedValue)
              }
            }}
            title="Property value"
            value={propertyValue}
          />

          <Slider
            ariaLabelForHandle="Property value, incremental"
            max={MAX_PROPERTY_VALUE}
            min={0}
            onChange={value => {
              setPropertyValue(value.toString())
            }}
            step={10000}
            value={parseInt(propertyValue)}
          />

          <GBPInputQuestion
            error={apiState.fieldError?.rentalIncome}
            onInput={value => {
              const boundedValue = Math.min(
                parseInt(value),
                MAX_RENTAL_INCOME
              ).toString()

              if (boundedValue !== rentalIncome) {
                setRentalIncome(boundedValue)
              }
            }}
            title="Rental income (monthly)"
            value={rentalIncome}
          />

          <Slider
            ariaLabelForHandle="Rental income (monthly), incremental"
            max={MAX_RENTAL_INCOME}
            min={1}
            onChange={value => {
              setRentalIncome(value.toString())
            }}
            value={parseInt(rentalIncome)}
          />
        </InputsContainer>

        {apiState.hasRequestFailed ? (
          requestErrorMessage
        ) : !apiState.response ? null : (
          <div aria-atomic aria-live="polite">
            <Body>
              Borrow up to:{" "}
              <span
                css={css`
                  ${typographyStyles.headline2}

                  display: block;
                `}
              >
                {formatPence(apiState.response.maximumBorrowingAmountV2)}
              </span>
            </Body>

            <Body>
              {`Buying? Budget ${formatPence(
                apiState.response.stampDutyV2
              )} extra for stamp duty.`}
            </Body>
          </div>
        )}
      </FormCard>

      <FormCard element="div">
        <InputsContainer>
          {monthlyMortgageTitle}

          <GBPInputQuestion
            error={apiState.fieldError?.deposit}
            onInput={value => {
              const boundedValue = Math.min(
                parseInt(value),
                MAX_DEPOSIT
              ).toString()

              if (boundedValue !== deposit) {
                setDeposit(boundedValue)
              }
            }}
            title="Deposit"
            value={deposit}
          />

          <Slider
            ariaLabelForHandle="Deposit, incremental"
            max={MAX_DEPOSIT}
            min={0}
            onChange={value => {
              setDeposit(value.toString())
            }}
            step={10000}
            value={parseInt(deposit)}
          />

          <GBPInputQuestion
            error={apiState.fieldError?.interestRate}
            onInput={value => {
              const boundedValue = value.endsWith(".")
                ? value
                : Math.min(parseFloat(value), MAX_INTEREST_RATE).toFixed(2)

              if (boundedValue !== interestRate) {
                setInterestRate(boundedValue)
              }
            }}
            title="Interest rate (%)"
            value={interestRate}
          />

          <Slider
            ariaLabelForHandle="Interest rate (%), incremental"
            max={MAX_INTEREST_RATE * 10}
            min={0}
            onChange={value => {
              setInterestRate((value / 10).toString())
            }}
            step={10}
            value={parseInt(interestRate) * 10}
          />
        </InputsContainer>

        {apiState.hasRequestFailed ? (
          requestErrorMessage
        ) : !apiState.response ? null : (
          <div aria-atomic aria-live="polite">
            <Body>
              Interest only:{" "}
              <span
                css={css`
                  ${typographyStyles.headline2}

                  display: block;
                `}
              >
                {formatPence(
                  apiState.response.interestOnlyMonthlyRepaymentAmount
                ) + "/month"}
              </span>
            </Body>

            <Body>
              {`Loan to value (LTV): ${apiState.response.loanToValue}%`}
            </Body>
          </div>
        )}
      </FormCard>

      <button css={css(mixins.invisible)} type="submit">
        Calculate
      </button>
    </form>
  )
}
